$opensans: 'Open Sans', sans-serif;
$fontawesome: "Font Awesome 5 Pro";
$bjc-blue: #00529B;
$dark-blue: #012E57;
$nc-blue: #6598C8;
$light-blue: #E5EFF7;
$baby-blue: #668FB7;
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;

* {
	font-family: 'Open Sans', sans-serif;
	font-weight: $regular;
	font-size: 16px;
}

html {
	overflow-x: hidden;
}

html.mobile-menu-active {
	overflow-y: hidden;
}

body {
	background: #FFFFFF;
}

main {
	border: 0px;
	margin-bottom: 0px;
}


/* font styles */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $opensans;
	color: $dark-blue;
}

h1 {
	font-weight: $light;
	font-size: 25px;
	line-height: 30px;
}

h1.bold {
	font-weight: $bold;
	line-height: 30px;
}

h2 {
	font-size: 22px;
	font-weight: $semi-bold;
	line-height: 30px;
	margin-bottom: 20px;
}

h3 {
	font-size: 20px;
	font-weight: $semi-bold;
	line-height: 30px;
}

h3.stripey-boi {
	margin-bottom: 40px;
}

h4 {
	font-weight: $regular;
	font-size: 16px;
	line-height: 25px;
}

h4.light {
	font-size: 15px;
	font-weight: $light;
	line-height: 25px;
}

h5 {
	font-weight: $regular;
	font-size: 15px;
	line-height: 25px;
}

h5.semi-bold {
	font-weight: $semi-bold;
	font-size: 15px;
	line-height: 25px;
}

h6 {
	font-weight: $regular;
	font-size: 11px;
	line-height: 25px;
}

.find-a-location .required,
.fad-sidebar .required {
	border: solid 2px #f98181 !important;
}

@media screen and (min-width: 992px) {

	h1 {
		font-size: 40px;
		line-height: 35px;
	}

	h1.bold {
		line-height: 35px;
	}

	h2 {
		font-size: 28px;
		line-height: 35px;
	}

	h3 {
		font-size: 20px;
		line-height: 30px;
	}

	h3.stripey-boi {
		font-size: 25px;
		margin-bottom: 80px;
		line-height: 40px;
		text-align: center;
	}

	h4 {
		font-size: 18px;
		line-height: 30px;
	}

	h4.light {
		font-size: 18px;
		line-height: 30px;
	}

	h5 {
		font-size: 16px;
		line-height: 30px;
	}

	h5.semi-bold {
		font-size: 15px;
		line-height: 25px;
	}

	h6 {
		font-size: 13px;
		line-height: 25px;
	}

} /* min width 992px */

p {
	font-weight: $regular;
	font-size: 16px;
	line-height: 26px;
	color: #707070;
	margin-bottom: 25px;
}

p.secondary {
	font-size: 14px;
}

p.small {
	font-size: 12px;
}

ul, ol {
	margin-bottom: 25px;
	li {
		color: #707070;
		line-height: 26px;
		font-size: 16px;
	}
}

@media screen and (min-width: 992px) {
	p {
		font-weight: $regular;
		font-size: 16px;
		line-height: 31px;
	}

	ul, ol {
		li {
			line-height: 31px;
		}
	}

}


a {
	text-decoration: underline;
	font-size: 16px;
	color: $bjc-blue;
}

main a {
	text-decoration: underline;
}

a:hover,
a:active,
a:focus {
	color: #042f56;
}

a.details {
	background-image: url(/Portals/_default/Skins/integriTheme/Images/caret-right-solid.png);
	background-repeat: no-repeat;
	background-position-x: 100%;
	padding-right: 12px;
	/* background-size: 80px; */
	background-size: 6px;
	background-position-y: 7px;
}

a.details:hover {
	background-image: url(/Portals/_default/Skins/integriTheme/Images/caret-right-solid-hover.png);
}

.full-width-link {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 5;
}

.clear {
	clear: both;
}


.hide {
	display: none;
}

.uppercase {
	text-transform: uppercase;
}


/* buttons */
.button {
	font-weight: $semi-bold;
	background: $bjc-blue;
    color: #FFFFFF;
    border-radius: 5px;
    text-decoration: none !important;
    text-align: center;
    padding: 14px 28px;
    display: inline-block;
    cursor: pointer;
}

.button:hover {
	background: $dark-blue;
	color: #FFFFFF;
}

.button:active,
.button:visited {
	color: #FFFFFF;
}


.button.uppercase {
	font-weight: $regular;
}

.button.square {
	border-radius: 0px;
	font-weight: $regular;
	padding: 11px 28px;
}

.button.hollow {
	border-radius: 0px;
	border: 1px solid $bjc-blue;
	background: #FFFFFF;
	color: $bjc-blue;
	font-weight: $regular;
	padding: 11px 28px;
}

.button.hollow:hover {
	background: $bjc-blue;
	color: #FFFFFF;
}


.button.transparent {
	background: transparent;
	border: 1px solid #FFFFFF;
	color: #FFFFFF;
}


.button.rounded {
	border-radius: 5px;
}

#show-more-benefits {
	margin-top: 25px;
}

.category-button {
	background: #E5EFF7;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 15px;
    text-decoration: none;
    display: inline;
}

.category-button.remove-filter {
	background: #E5EFF7;
    padding: 10px 20px 10px 35px;
    border-radius: 20px;
    font-size: 15px;
    text-decoration: none;
    display: inline;
    cursor: pointer;
    position: relative;
}

.category-button.remove-filter:before {
	content: '\f057';
    font-family: "Font Awesome 5 Pro";
    position: absolute;
    top: 11px;
    left: 13px;
    font-size: 17px;
}

.color-overlay.active {
	background: rgba(0, 0, 0, .4);
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 998;
}

.main-header.mobile {
	background: #FFFFFF;
	padding: 25px 0px;
	border-bottom: 0px;

	.logo {
		img {
			max-width: 200px;
			padding-top: 5px;
		}
	}

	.call-us-container {
		width: 26px;
	    position: absolute;
	    right: 85px;
	    margin-top: 2px;

		a {
			 i {
			 	font-size: 26px;
			 }
		}
	} /* call-us-container */

	.mobile-menu-button {
		text-align: right;

		#trigger {

			i {
				color: $bjc-blue;
				font-size: 35px;
			}
		}

	} /* mobile-menu-button */

	.mobile-menu {
		background: $bjc-blue;
		position: absolute;
		top: 0px;
		right: -300px;
		z-index: 999;
		height: 100%;
		display: block;
		width: 300px;
		transition: .5s;
		-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
		display: none;

		.mobile-menu-close {
			text-align: right;
			padding-top: 12px;
			padding-right: 30px;

			#trigger-close {

				i {
					font-size: 45px;
					color: #FFFFFF;
				}
			}

		} /* mobile-menu-close */

		#dnnMenu {
			ul.nav {
				margin: 0px;
				margin-top: 10px;

				li {
					background: $bjc-blue !important;
					display: block;
					border: 0px;
					width: 100%;

					a {
						color: #FFFFFF !important;
						padding: 20px;
						text-decoration: none;

						span {
							padding: 0px;
							font-size: 16px;
							line-height: 22px;
						}

						.navbar-toggle.sub-arrow {
							display: none;
						}
					}

					a:active,
					a:focus {
						background: $bjc-blue;
					}

					a:hover {
						color: #FFFFFF;
					}
				} /* li */

				li:hover {

					a {
						background: $bjc-blue;
						color: #FFFFFF;

						span {
							color: #FFFFFF;
						}
					}
				} /* li:hover */

				li.dropdown {

					a {
						.caret {
							display: none;
						}
					}

					.dropdown-menu {
						display: none !important;
					}
				} /* dropdown */

			} /* ul.nav */
		} /* dnnMenu */

		hr {
			border: .5px solid #668FB7;
			opacity: .3;
			margin: 8px 0px;
		}

		.utility {
			padding: 15px;

			.search {
				display: block;
				width: 100%;
				margin-bottom: 35px;

				.searchInputContainer {
					width: 100%;
					display: inline-block;
				}

				input {
					background: #1563A9;
					-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
					box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
					position: relative;
					color: #E6E6E6;
					display: block;
					width: 100%;
					padding-right: 45px;
					padding-left: 30px;
					height: 35px;
				}

				input::placeholder {
					color: #E6E6E6;
				}

				.dnnSearchBoxClearText {
					display: none;
				}

				.searchSkinObjectPreview {
					display: none;
				}

				#dnn_ucHeader_dnnSearch_cmdSearch {
					background: $nc-blue;
				    color: #FFFFFF;
				    text-align: center;
				    display: block;
				    position: absolute;
				    top: 0px;
				    right: 0px;
				    text-align: center;
				    padding: 8px;
				    text-transform: uppercase;
				    font-size: 15px;

				}
			} /* search */

			.search::before {
				content: '\f002';
				font-family: $fontawesome;
				color: #E6E6E6;
				position: absolute;
				top: 2px;
				left: 5px;
				z-index: 99;
				padding-top: 6px;
				padding-left: 3px;
				font-size: 16px;
			}

			.link {
				margin-bottom: 20px;
				a {
					color: #FFFFFF;
					line-height: 22px;
					font-size: 16px;
					text-decoration: none;
				}

				i {
					padding-right: 3px;
				}

				img {
					width: 18px;
					height: 18px;
					display: inline-block;
				}
			}
		} /* utility */

	} /* mobile-menu */

	.mobile-menu.active {
		right: 0px;
		transition: .5s;
		display: block;
		z-index: 9999;
	}

} /* header.main-header.mobile */

.utility-header {
	display: none;
	background: #00509D;
	padding: 8px 0px;

	.content-container {
		text-align: right;
	}

	.call-us.link {
		float: left;
		margin-left: 8px;
	}

	.link {
		display: inline-block;
		margin-right: 40px;
		margin-top: 7px;

		i {
			padding-right: 3px;
			font-size: 16px;
		}

		a {
			color: #FFFFFF;
			font-size: 15px;
			text-decoration: none;
		}

		a:hover {
			opacity: .8;
		}

		img {
			height: 19px;
			width: 20px;
			line-height: 18px;
			margin-top: -3px;
		}
	}

	.search {
		display: inline-block;
		width: 100%;
		max-width: 265px;
		margin-right: 17px;

		.searchInputContainer {
			width: 100%;
			display: inline-block;
		}

		input {
			background: #1563A9;
			-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
			position: relative;
			color: #E6E6E6;
			display: block;
			width: 100%;
			padding-right: 45px;
			padding-left: 30px;
			height: 35px;
		}

		input::placeholder {
			color: #E6E6E6;
		}

		input:active,
		input:focus,
		input:hover {
			color: #FFFFFF;
			background: #367ec2;
		}

		.dnnSearchBoxClearText {
			display: none;
		}

		.searchSkinObjectPreview {
			display: none;
		}

		#dnn_ucHeader_dnnSearchUtility_cmdSearch {
			background: $nc-blue;
		    color: #FFFFFF;
		    text-align: center;
		    display: block;
		    position: absolute;
		    top: 0px;
		    right: 0px;
		    text-align: center;
		    padding: 8px;
		    text-transform: uppercase;
		    font-size: 15px;
		    text-decoration: none;
		}

		#dnn_ucHeader_dnnSearchUtility_cmdSearch:hover {
		    background: #367ec2;
		}
	} /* search */

	.search::before {
		content: '\f002';
		font-family: $fontawesome;
		color: #E6E6E6;
		position: absolute;
		top: 2px;
		left: 5px;
		z-index: 99;
		padding-top: 6px;
		padding-left: 3px;
		font-size: 16px;
	}

} /* utility-header */


header.main-header.desktop {
	display: none;
	padding: 20px 0px;
	border-bottom: 0px;

	.logo {
		max-width: 265px;	
		padding-top: 8px;

		img:hover {
			opacity: .8;
		}
	}

	.desktop-menu {
		float: right;

		#dnnMenu {
			ul.nav {

				li {

					a {
						font-size: 16px;
						color: $bjc-blue;
						padding: 15px 0px;
						margin-right: 25px;
						margin-left: 25px;
						text-decoration: none;
					}

					a:visited,
					a:active,
					a:focus {
						background: #FFFFFF;
					}

					a:hover {
						background: #FFFFFF;
						color: #012e57;
					}
				}

				li.hover {

					a {
						background: #FFFFFF;
						color: #012e57;
					}
				}

				li.active {

					a {
						color: #012e57;
						border-bottom: 1px solid #012e57;
						font-weight: $semi-bold;
					}
				}

				li:last-of-type {

					a {
						margin-right: 0px;
					}
				}

				li.dropdown {

					a {
						.caret {
							display: none;
						}
					}

					.dropdown-menu {
						display: none !important;
					}
				} /* dropdown */


				li.dropdown:hover {

					.dropdown-menu {
						display: none !important;
					}
				}


			} /* nav */
		} /* dnnMenu */
	} /* desktop-menu */

} /* header.main-header.desktop */

@media screen and (min-width: 992px) {
	header.main-header.mobile {
		display: none;
	}

	.utility-header {
		display: block;
	}

	header.main-header.desktop {
		display: block;
	}
} /* min-width 992px */

@media screen and (min-width: 1200px) {

	header.main-header.desktop {

		.desktop-menu {

			#dnnMenu {

				ul.nav {

					li {

						a {

						}
					}
				} /* nav */
			} /* dnnMenu */
		} /* desktop-menu */
	} /* header.main-header.desktop */

} /* min-width 1200px */

@media screen and (max-width: 345px) {
	.main-header.mobile .logo img {
	    max-width: 175px;
	    padding-top: 5px;
	}
}

footer.upper-footer {
	background: $light-blue;
	text-align: center;
	padding: 40px 0px;
	color: #707070;

	.info {

		img {
			max-width: 225px;
		}

		.address {
			margin: 25px 0px;
			line-height: 25px;

			a {
				text-decoration: none;
			}
		}
	} /* info */

	.links {
		border-top: 1px solid #C2C9CE;
		border-bottom: 1px solid #C2C9CE;
		padding: 30px 0px;

		.link {
			margin-bottom: 5px;
			a {
				color: $bjc-blue;
				text-decoration: none;
			}

			a:hover {
				color: $dark-blue;
			}

			i {
				padding-right: 4px;
			}

			span {
				text-decoration: underline;
			}

		} /* link */

		.link:last-of-type {
			margin-bottom: 0px;
		}
	} /* links */

	.social-copyright {
		padding: 30px 0px;

		.social-media {
			margin-bottom: 20px;

			i {
				color: $bjc-blue;
				font-size: 35px;
			}

			i:hover {
				color: #012e57;
			}

			i.fa-facebook-square {
				margin-right: 15px;
			}
		} /* social-media */

		.copyright-container {

			p {
				margin-bottom: 0px;
				line-height: 25px;

			}

		} /* copyright-container */

	} /* social-copyright */

	.disclaimer {

		p {
			font-style: italic;
			font-size: 14px;
			margin-bottom: 0px;
			line-height: 25px;
		}
	} /* disclaimer */

} /* footer.upper-footer */

@media screen and (min-width: 992px) {
	footer.upper-footer {
		text-align: left;
		padding: 60px 0px 40px 0px;

		.info {

			.address {
				margin-top: 20px;
			}
		} /* info */

		.links {
			border-top: 0px;
			border-bottom: 0px;
			padding-top: 35px;
		}

		.social-copyright {
			padding-top: 35px;
		}

		.disclaimer {

			p {
				text-align: center;
				padding-top: 50px;
			}
		} /* disclaimer */
	} /* footer.upper-footer */
} /* min-width 992px */

@media screen and (min-width: 1200px) {

	footer.upper-footer {
		.social-copyright {
			padding-left: 40px;
		}

	} /* footer.upper-footer */
} /* min width 1200px */


footer.bjc-footer {
	background: #00509D;
	padding: 60px 0px 20px 0px;
	text-align: center;

	* {
		color: #FFFFFF;
		text-decoration: none;
	}

	hr {
		border: 1px solid #266CAA;
		margin-top: 30px;
		margin-bottom: 20px;
	}

	.bjc-logo {
		margin-bottom: 25px;
	}

	.address-contact {
		p {
			margin-bottom: 2px;
			color: #FFFFFF !important;
			line-height: 23px;

			a {
				color: #FFFFFF;
			}
		}
	}

	.copyright {
		margin-top: 30px;
		margin-bottom: 40px;
		float: none;
	}

	.footer-menu {
		ul {
			margin-left: 0px;
			padding-left: 0px;
			margin-bottom: 0px;

			li {
				list-style-type: none !important;
				margin-bottom: 8px;
				display: inline-block;
				margin-right: 25px;

				a {
					color: #FFFFFF;
				}

				a:hover {
					text-decoration: none;
					color: #FFFFFF;
				}
			}
		} /* ul */
	} /* footer-menu */

} /* footer */

@media screen and (min-width: 992px) {

	footer.bjc-footer {
		padding: 60px 0px 30px 0px;

		hr {
			margin-top: 65px;
			margin-bottom: 30px;
		}

		.footer-menu {
			ul {
				li {
					display: inline-block;
					margin-right: 30px;

					a:hover {
						opacity: .8;
					}
				}
			}
		}
	} /* footer */

} /* 992px up */

.dnnFormMessage.dnnFormWarning  {
	display: none;
}

@media screen and (max-width: 347px) {
	.homepage .hero .hero-content .hr-container hr {
		margin: 0 auto;
	}
}
 
/* HOME PAGE */
.homepage {

	.hero {
		background-image: url("/Portals/_default/Skins/integriTheme/images/homepage-hero-mobile.jpg");
		background-size: cover;
		background-position: right top;
		position: relative;
		padding: 25px 0px;
		overflow: visible;
		max-height: 585px;
		margin-bottom: 15px;

		.color-overlay-element {
			z-index: 1;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgba(7, 68, 119, .5);
		}

		.container {
			z-index: 10;
		}

		.hero-content {
			color: #FFFFFF;
			text-align: center;

			.hr-container {
				max-width: 315px;
				margin: 0 auto;

				hr {
					border: 1px solid #FFFFFF;
					width: 18px;
					margin-bottom: 5px;
				}
			}

			h2, h1 {
				color: #FFFFFF;
				font-weight: $light;
				font-size: 25px;
				span {
					font-weight: $semi-bold;
					font-size: 35px;
				}
			} /* h2 */

			p {
				color: #FFFFFF;
				text-align: center;
				max-width: 365px;
				margin: 0 auto;
			}

			a {
				font-weight: $semi-bold;
			}

			a.button {
				color: #FFFFFF !important;
			}
		} /* hero-content */


		.find-a-location {
			margin-top: 40px;
			background: #FFFFFF;
			padding: 28px;
			-webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);
			box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.08);

			.form-group {
				position: relative;

				i {
					color: $bjc-blue;
					font-size: 18px;
					position: absolute;
					top: 16px;
					left: 15px;
					z-index: 99;
				}
			}

			input {
				width: 100%;
				display: block;
				min-width: 100%;
				max-width: 100%;
			}

			input, select {
				border-radius: 0px;
				box-shadow: none !important;
				height: 50px;
				color: #707070;
				font-size: 15px;
				padding-left: 45px !important;
				border: 1px solid #e0e8f0;
				position: relative;
			}

			select::-ms-expand {
			    display: none;
			}

			input#doctor {
				margin-bottom: 0px !important;
			}

			.twitter-typeahead {
				width: 100%;
			}

			.form-control.typeahead.tt-hint {
				background: #FFFFFF;
				box-shadow: none !important;
			}

			.tt-menu {
				margin-top: -7px;
				border-radius: 0px;
			}

			#bjc-ps-hm {
				font-weight: 600;
				background: #00529B;
				color: #FFFFFF;
				border-radius: 5px;
				text-decoration: none !important;
				text-align: center;
				padding: 14px 28px;
				display: inline-block;
				cursor: pointer;
				display: block;
				text-transform: uppercase;
				font-weight: normal;
				margin-top: 20px;
				border: 1px solid #707070;
				position: relative;
			}

			#bjc-ps-hm:hover {
				background: #012E57;;
			}

			select {
				-webkit-appearance: none;
			  	-moz-appearance: none;
			  	appearance: none;
			  	background-image: url("/Portals/_default/Skins/integriTheme/images/caret-down-solid.png");
			  	background-position: right center;
			  	background-repeat: no-repeat;
			  	background-size: 11px;
			  	background-position-x: 95%;
			}

			select::-ms-expand {
			    display: none;
			}
		} /* find a location */

	} /* hero */

	.intro-text {
		padding: 40px 0px;
		padding-top: 120px;

		p {
			text-align: center;
			color: #333;
			margin-bottom: 0px;
		}
	} /* intro-text */

	.find-a-doctor {
		background-image: url("/Portals/_default/Skins/integriTheme/images/find-a-doctor-mobile.png");
		background-size: cover;
		background-position: center top;
		position: relative;
		padding: 60px 0px;

		.color-overlay-element {
			z-index: 1;
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
			background: rgba(7, 68, 119, .5);
		}

		.container {
			z-index: 10;
			position: relative;
		}

		hr {
			border: 1px solid #FFFFFF;
			width: 45px;
			margin: 0 auto;
			margin-bottom: 10px;
		}

		h2 {
			color: #FFFFFF;
			text-align: center;
		}

		p {
			color: #FFFFFF;
			text-align: center;
		}

		.button {
			border: 1px solid #FFFFFF;
			color: #FFFFFF;
			text-align: center;
			background: transparent;
			text-align: center;
			font-weight: $semi-bold;
			margin: 0 auto;
			font-size: 15px;
			max-width: 295px;
			margin-bottom: 10px;
			display: block;
			padding: 15px 28px;

			i {
				font-size: 14px;
				padding-right: 4px;
			}
		} /* button */

		.button:hover {
			background: #FFFFFF;
			color: $bjc-blue;
		}

	} /* find-a-doctor */

	.careers-container {
		padding: 60px 0px;
		text-align: center;

		hr {
			margin: 0 auto;
    		max-width: 45px;
    		margin-top: 0px;
    		margin-bottom: 10px;
    		border: 1px solid #00529B;
		}

		.left {
			margin-bottom: 10px;
		}

	} /* careers-container */

	.hospital-logos {

		* {
			-webkit-tap-highlight-color: transparent !important;
			box-shadow: none !important;
			-webkit-touch-callout: none;
		    -webkit-user-select: none;
		    -khtml-user-select: none;
		    -moz-user-select: none;
		    -ms-user-select: none;
		    user-select: none;
		    border: 0px !important;
		    outline: none !important;
		}    

		#hospital-slider {
			img {
				width: 50%;
				float:left !important;
				text-align: center;
				padding: 20px;
			}

			.slick-next {
				right: -10px;
				background: rgba(102, 143, 183, .5);
				min-height: 85px;
			}

			.slick-next::before {
				font-family: $fontawesome;
				content: '\f054';
				
			}

			.slick-prev {
				left: -10px;
				background: rgba(102, 143, 183, .5);
				min-height: 85px;
			}

			.slick-prev::before {
				font-family: $fontawesome;
				content: '\f053';
			}

			.slick-dots {
				bottom: -40px;
			}

			.slick-dots li.slick-active button:before {
				color: $bjc-blue;
			}

			.slick-dots li button:before {
				font-size: 45px;
				opacity: 1;
				color: rgba(102, 143, 183, 1);
			}

		} /* hospital-slider */
	} /* hospital-logos */

} /* homepage */

@media screen and (min-width: 600px) {
	.homepage {

		.hero {
			padding: 40px 0px 55px 0px;

			.hero-content {

				p {
					max-width: 550px;
				}

			} /* hero-content */

		}/*hero */

		.intro-text {
			padding: 55px 0px;
			padding-top: 100px;

			p {
				max-width: 800px;
				margin: auto;
				margin-bottom: 20px;
			}
		}

		.find-a-doctor {

			p {
				max-width: 720px;
				margin: auto;
				margin-bottom: 20px;
			}

			.row {
				max-width: 650px;
				margin: 0 auto;
			}
		}

		.careers-container {

			p {
				max-width: 720px;
				margin: auto;
				margin-bottom: 20px;
			}

			.button-group {
				max-width: 600px;
				margin: 0 auto;
			}
		} /* careers-container */

	} /* homepage */
} /* min width 600 */

@media screen and (min-width: 768px) {
	.homepage {

	} /* homepage */
} /* min width 768 */

@media screen and (min-width: 992px) {
	.homepage {

		.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/homepage-hero-desktop.jpg');
			padding: 90px 0px 90px 0px;
			margin-bottom: 0px;

			.find-a-location {
				margin-top: 0px;
			}

			.hero-content {
				text-align: left;

				.hr-container {
					max-width: 100%;

					hr {
						border: 2px solid #FFFFFF;
						width: 30px;
						margin-bottom: 17px;
						margin-right: auto;
					}
				}

				h2, h1 {
					font-size: 40px;
					line-height: 50px;

					span {
						font-size: 55px;
					}
				}

				p {
					text-align: left;
					margin: 0px;
					margin-bottom: 30px;
				}

				.button {
					max-width: 275px;
				}
			}
		} /* hero */

		.intro-text {
			padding: 75px 0px;

			p {
				margin-bottom: 0px;
			}
		}

		.find-a-doctor {
			padding: 90px 0px;
			background-image: url('/Portals/_default/Skins/integriTheme/Images/find-a-doctor-desktop.jpg');

			.button {
				margin-bottom: 0px;
			}
		} /* find-a-doctor */

	} /* homepage */
} /* min width 992 */

@media screen and (min-width: 1200px) {
	.homepage {

		.hero {
			padding: 120px 0px;
			margin-bottom: 0px;
		}

	} /* homepage */
} /* min width 1200 */


/* PAGE TEMPLATES */
main.sub-page {
	// background: #F4F6F7;

	.content {
		background: #FFFFFF;
		padding: 40px 0px;
	}
} /* sub-page */

@media screen and (min-width: 992px) {

	main.sub-page {
		background: #F4F6F7;

		.content {
			padding: 60px 120px;
		}
	}
} /* min-width 992px */

/* find a practice page */
main.sub-page.find-a-practice {

	.blue-header {
		padding-bottom: 160px;
	}

	#searchResults {

		.fad-sidebar {
			background: #FFFFFF;
			-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
			box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
			margin-top: -120px;
			margin-bottom: 40px;

			.click-to-close {
				background: $light-blue;
				color: $bjc-blue;
				text-align: center;
				font-weight: $semi-bold;
				padding: 20px;
				position: relative;

				.full-width-link {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
				}
			}  /* click-to-close */

			.content {
				padding: 25px 20px;
			}
		} /* fad-sidebar */

		.fad-sidebar.minimized {
			margin-bottom: 100px;

			.click-to-close {

				i:before {
					content: '\f067';
				}
			}

			.content {
				display: none;
				padding: 0px;
			}
		}

		.form-group {
			margin-bottom: 0px;
			position: relative;

			i {
				position: absolute;
				top: 16px;
				left: 15px;
				z-index: 99;
				color: $bjc-blue;
				font-size: 18px;
			}
		}

		label {
			display: none;
		}

		input, select {
			border-radius: 0px;
			box-shadow: none !important;
			height: 50px;
			color: #707070;
			font-size: 15px;
			padding-left: 45px !important;
			border: 1px solid #C9DAEC;
			position: relative;
		}

		select {
			-webkit-appearance: none;
		  	-moz-appearance: none;
		  	appearance: none;
		  	background-image: url("/Portals/_default/Skins/integriTheme/images/caret-down-solid.png");
		  	background-position: right center;
		  	background-repeat: no-repeat;
		  	background-size: 9px;
		  	background-position-x: 95%;
		}

		select::-ms-expand {
		    display: none;
		}

		#searchBtn {
			font-weight: 600;
			background: #00529B;
			color: #FFFFFF;
			border-radius: 5px;
			text-decoration: none !important;
			text-align: center;
			padding: 14px 28px;
			display: inline-block;
			cursor: pointer;
			display: block;
			text-transform: uppercase;
			font-weight: normal;
			margin-top: 20px;
			border: 1px solid #707070;
			position: relative;
			width: 100%;
			cursor: pointer;
		}

		#numberOfResults {
			text-transform: uppercase;
			color: $bjc-blue;
			font-weight: $semi-bold;
		}

		#map {
			width: 100%;
			margin: 15px 0px 30px 0px;
		}

		#resultList {

			.card {
				width: 100%;
				border: 0px;
				-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
				box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
				background: #FFFFFF;
				margin-bottom: 20px;
				padding: 0px;

				.card-body {
					padding: 16px 20px;
					padding-bottom: 0px;
				}

				.card-header {
				}

				.location_thumbnail {
					display: none;
				}

				.practice-title {

					h3 {
						float: left;
						width: calc(100% - 110px);
					}

					h3:hover {
						opacity: .9;
					}
				} /* practice-title */

				a:hover {
					opacity: .8;
				}

				i {
					color: $bjc-blue;
					padding-right: 5px;
				}

				.practice-distance {
				    float: right;
				    color: $bjc-blue;
				}

				.practice-address {
					margin-bottom: 0px;

					i {
						color: $bjc-blue;
						padding-right: 9px;
						font-size: 17px;
					}
				}

				.practice-address.line-2 {
					padding-left: 22px;
				}

				.practice-phone {
					margin-bottom: 15px;
    				margin-top: 15px;

					a {
						text-decoration: none;
					}
					i {
						color: $bjc-blue;
						padding-right: 5px;
					}
				}

				.practice-hours {
					margin-bottom: 25px;
					i {
						color: $bjc-blue;
						padding-right: 5px;
					}
				}

				.card-accordion {

					.card-header {
						padding: 0px 20px;
						padding-bottom: 16px;

						a.btn.btn-link {

							i {
								transform: rotate(180deg);
								transition: .3s;
							}
						}

						a {
							padding: 0px;
							font-size: 16px;
							transform: none;
							text-decoration: none;
							font-weight: bold;
						}

						a.btn.btn-link.collapsed {

							i {
								transform: none;
								transition: .3s;
							}
						}
					}

					.bjcAccordian {
						margin-left: 0px;

						img {
							display: none;
						}

						li {

							.doctor {
								border-top: 1px solid #DBE5EE;
								padding: 20px;
								padding-bottom: 27px;

								h3 {
									a {
										font-size: 20px;
										text-decoration: none;
										color: #012E57;
									}

									a:hover {
										opacity: .8;
									}
								}

								.doctor-info {
									i, b, p, a {
										color: #012E57;
										font-size: 15px;
									}

									i {
										color: #5F93C1;
									}

									b {
										font-weight: $semi-bold;
									}

									.doctor-practice {
										margin-bottom: 12px;

										i {
											padding-right: 5px;
										}
									}
									.doctor-phone {
										margin-bottom: 0px;
										i {
											padding-right: 3px;
										}

										a {
											text-decoration: none;
										}
									}
								} /* doctor-info */
							} /* doctor */
						} /* li */

						li:nth-child(even) {
							background: #F8FBFD;
						}
					} /* bjcAccordian */
				} /* card-accordion */

			} /* card */

			hr {
				display: none;
			}

		} /* resultsList */

		#buttons {
			margin-bottom: 45px;
			margin-top: 30px;

			#backBtn, #nextBtn {
				background: transparent;
				color: $bjc-blue !important;
				text-decoration: none;
				border: 0px;
				font-size: 16px;
				box-shadow: none !important;
				cursor: pointer;
			}

			#backBtn:before {
				content: '\f053';
				font-family: $fontawesome;
				font-size: 12px;
				padding-right: 5px;
			}

			#nextBtn:after {
				content: '\f054';
				font-family: $fontawesome;
				font-size: 12px;
				padding-left: 5px;
			}

			#pageNumber {
				font-weight: $semi-bold;
			}

		} /* buttons */

	} /* searchresults */

} /* find a practice */

@media screen and (min-width: 992px) {

	main.sub-page.find-a-practice {

		.blue-header {
			margin-bottom: 50px;
			padding-bottom: 85px;
		}

		#searchResults {

			.fad-sidebar {
				margin-top: 0px;
				background: transparent;
				box-shadow: none !important;
				-webkit-box-shadow: none !important;

				.click-to-close {
					display: none;
				}

				.content {
					background: transparent;
					padding-top: 0px;
					padding-bottom: 0px;
				}

				select, input {
					border: 1px solid #C9DAEC;
				}

				select::-ms-expand {
				    display: none;
				}
			}

			#map {
				margin-top: 0px;
				height: 445px;
			}

			#resultList {
				.card {

					.location_thumbnail {
						display: block;
					}

					.card-accordion {

						.bjcAccordian li {
							margin: 0px 15px;
						}
					}

				} /* card */
			} /* resultsList*/


		} /* searchResults */
	} /* main.sub-page.find-a-practice */
} /* min-width: 992px */

/* PRACTICE DETAIL PAGES */
main.sub-page.practice-detail-page {
	background: #FFFFFF;

	#blue-header {
		background: linear-gradient(#0060b5 0%, #00529b 100%);
		padding: 70px 0px;
		text-align: center;
		color: #FFF;

		hr {
			width: 45px;
			border: 1px solid #FFF;
			margin: 0 auto;
			margin-bottom: 0px;
			margin-top: 0px;
			margin-bottom: 10px;
		}

		h2, h1 {
			font-weight: $light;
			color: #FFFFFF;
			font-size: 22px;
			span {
				font-weight: $bold;
			}
		} /* h2 */

		p {
			margin-bottom: 0px;
			color: #FFF;
			font-size: 14px;
		}
	} /* blue-header */

	.practice-details {

		.practice-sidebar {
			-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
			box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
			margin-top: -40px;
			margin-bottom: 45px;

			.content {
				padding: 15px;

				.header {
					background: $bjc-blue;
					padding: 20px 10px;
					text-align: center;
					margin-bottom: 25px;

					h4 {
						color: #FFFFFF;
						font-size: 22px;
						text-align: left;
						font-weight: $semi-bold;
						margin-bottom: 0px;
						display: inline;
						text-align: center;
					}
				}

				.address {
					margin-bottom: 15px;

					p {
						font-size: 15px;
						margin-bottom: 0px;
						line-height: 20px;
						padding-left: 16px;
						color: #333;
					}

					p::first-of-type {
						padding-left: 0px;
					}
				}

				.address::before {
					content: '\f3c5';
					font-family: $fontawesome;
					margin-right: 5px;
					float: left;
					color: $bjc-blue;
					font-size: 16px;
				}

				.building {
					margin-bottom: 15px;

					p {
						font-size: 15px;
						color: #333;
						margin-bottom: 0px;

						i {
							margin-right: 3px;
						}
					}
				}

				.phone {
					margin-bottom: 15px;

					p {
						font-size: 15px;
						margin-bottom: 0px;
						line-height: 20px;
						color: #333;

						a {
							font-size: 15px;
							text-decoration: none;
						}
					}
				}

				.phone::before {
					content: '\f095';
					font-family: $fontawesome;
					margin-right: 5px;
					float: left;
					color: $bjc-blue;
					font-size: 14px;
				}

				.hours {
					margin-bottom: 15px;

					.hours-label {
						font-size: 15px;
						color: #333;
					}

					.hours-listing {

						p {
							font-size: 15px;
							margin-bottom: 0px;
							line-height: 20px;
							color: #333;
							padding-left: 21px;

							span.day {
								display: inline-block;
								width: 100px;
							}

							span.day::after {
								content: ":";
							}
						}

					}
				}

				.hours::before {
					content: '\f017';
					font-family: $fontawesome;
					margin-right: 5px;
					float: left;
					color: $bjc-blue;
					font-size: 16px;
				}

				#map {
					overflow: auto;
				    position: relative;
				    display: block;
				    width: 100%;
				    height: 200px;
				    overflow-x: hidden;
				    overflow-y: hidden;
				    margin-bottom: 20px;
    				margin-top: 25px;
				}

				.button {
					display: block;
					text-transform: uppercase;
					font-weight: normal;
				}
			} /* content */

		} /* practice-sidebar */

		.practice-summary-container {
			hr {
				clear: none;
				max-width: 45px;
				border: 1px solid $bjc-blue;
				margin-bottom: 15px;
			}
		}

	} /* practice-details */




	div.doctors-list {
		background: #f7fafc;
		padding: 0px 0px 45px 0px;
		padding-bottom: 0px;

		#providers-heading {
			hr {
				max-width: 45px;
				margin: 0 auto;
				border: 1px solid $bjc-blue;
				margin-bottom: 15px;
			}

			h2 {
				text-align: center;
				margin-bottom: 40px;
			}
		} /* providers-heading */


		#no-providers {
			text-align: center;

			h4 {
				font-weight: $semi-bold;
			}
		}

		ul.doctors-list {
			max-width: 750px;
			margin: 0 auto;

			li {
				list-style-type: none;
				background: #FFFFFF;
				-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
				box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
				margin-bottom: 15px;

				.card {
					margin-top: 0px;

					.doctor-image {
						background-position: center;
					    background-size: cover;
					    margin: 20px;

						img {
							width: 100%;
							height: auto;
						}
					}

					.doctor-detail {
						padding: 20px;

						.card-title {

							h3 {
								margin-bottom: 20px;

								a {
									text-decoration: none;
									color: #012E57;
									font-weight: $semi-bold;
									font-size: 20px;
								}
							}
						}

						p.specialties {
							color: #012E57;
							font-size: 14px;
							margin-bottom: 10px;
						}

						p.call-for-appointment {
							color: #012E57;
							font-size: 14px;
							margin-bottom: 25px;

							a {
								color: #012E57;
								font-size: 14px;	
								text-decoration: none;
							}
						}

						.tm_td {

							p {
								font-size: 14px;
								margin-bottom: 25px;
							}
						}

						p.view-profile {
							
							a {
								color: #012E57;
								font-size: 14px;
								color: $bjc-blue;
								font-weight: bold;
								text-decoration: none;
							}
						}
					}
				}
			}
		}

		> .container {
			width: 100%;
    		padding: 0px;
		}

	} /* doctors-list */

	.additional-info-accordions {
		padding: 45px 0px;

		#additionalInfo {
			width: 100%;
			padding: 0px;

			#accordion {
				max-width: 885px;
				margin: 0 auto;

				.card-accordion {
					margin-top: 0px;
					margin-bottom: 15px;
					border: 0px;
					-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
					box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
					position: relative;

					.card-header {
						h5 {
							margin-bottom: 0px;

							a {
								text-decoration: none;
								color: #012E57;
								font-weight: $semi-bold;
								font-size: 20px;
								padding: 28px 15px;
								display: block;
								text-align: left;
								background: #FFFFFF;
							}

							a::after {
								content: '\f055';
								font-family: $fontawesome !important;
								color: $bjc-blue;
								position: absolute;
								top: 28px;
								right: 15px;
								font-size: 25px;
								font-weight: normal !important;				
							}

							a.active {
								background: $bjc-blue;
								color: #FFFFFF;
							}

							a.active::after {
								content: '\f056';
								color: #FFFFFF;
							}
						}
					} /* card-header */

					.card-body {
						padding: 15px 20px;
						font-size: 14px;
						background: #FFFFFF;

						ul.bjcAccordian {
							margin-bottom: 0px;

							li {
								font-size: 14px;
								padding-right: 10px;
								line-height: 18px;
								margin-bottom: 10px;
							}
						}
					} /* card-body */
				} /* card accordion */

				.card-accordion::last-of-type {
					margin-bottom: 0px;
				}

			} /* accordion */

		} /* additionalInfo */

	} /* additional-info */


	.back-to-list {
		max-width: 885px;
		margin: 0 auto;
		margin-bottom: 45px;
		margin-top: 40px;

		a {
			text-align: left;
			text-decoration: none;
			font-size: 15px;
		}

	} /* back to list */


}   /* main.sub-page.practice-detail-page */


@media screen and (min-width: 992px) {

	main.sub-page.practice-detail-page {

		#blue-header {
			padding: 85px 0px;

			h2, h1 {
				font-size: 35px;
				text-align: left;
				width: calc(100% - 400px);
			}

			p {
				font-size: 18px;
			}

			hr {
				margin: 0px;
				margin-bottom: 15px;
			}
		} /* blue header */

		.practice-details {

			.practice-sidebar {
				width: 360px;
				float: right;
				margin-left: 66px;
				margin-top: -120px;

				.content {

					.header {

						h4 {
						}
					}
				} /* content */

			} /* practice-sidebar */

		} /* practice-details */

		.practice-summary-container {
			padding-top: 80px;
		}

		.col-xs-12.col-sm-12.col-md-8 {
			padding-left: 0px;
		}

		div.doctors-list ul.doctors-list li .card .doctor-detail {
			padding-left: 0px;
		}

	} /* main.sub-page.practice-detail-page */
	
} /* min-width 992px 



/* Careers page */
main.sub-page.careers {
	background: #FFFFFF;

	.hero {
		background-image: url('/Portals/_default/Skins/integriTheme/Images/careers-hero-mobile.jpg');
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		padding: 70px 0px 40px 0px;

		.content-container {
			max-width: 155px;
			text-align: center;
			margin: 0 auto;

			hr {
				border: 2px solid #FFFFFF;
				max-width: 19px;
				margin-top: 0px;
				margin-bottom: 4px;
				margin-left: 4px;
			}

			h2, h1 {
				color: #FFFFFF;
				font-weight: $light;
				font-size: 25px;
				line-height: 34px;
				margin-bottom: 60px;

				span {
					font-weight: $semi-bold;
					font-size: 35px;
				}
			}
		} /* content-container */

		p {
			color: #FFFFFF;
		}

		p.tag-line {
			font-weight: $light;
			text-align: center;
			max-width: 355px;
			margin: 0 auto;
			margin-bottom: 65px;
		}

		.button {
			margin: 0 auto;
			text-align: center;
			display: block;
			max-width: 310px;
		}

		p.view-all-other {
			text-align: center;

			a {
				text-decoration: underline;
				font-size: 14px;
				font-weight: $regular;
				color: #FFFFFF;

				i {
					text-decoration: none;
					margin-left: 5px;
					font-size: 12px;
				}
			}

			a:hover {
				opacity: .8;
			}
		} /* view-all-other */

	} /* hero */

	.intro {
		padding: 20px 0px;

		.video-container {
			margin-bottom: 20px;

			.video-preview {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/careers-video-preview-mobile.jpg');
				background-size: cover;
				background-position: left top;
				min-height: 300px;
				position: relative;

				.play-button-container {
					background: rgba(255, 255, 255, 0.1);
					border-radius: 22px;
					height: 40px;
					width: 40px;
					/* margin: 0 auto; */
					position: absolute;
					right: 10px;
					bottom: 25px;
					display: flex;
					align-items: center;
					justify-content: center;

					.play-button {
						background: #FFFFFF;
						border-radius: 22px;
						height: 32px;
						width: 32px;
						text-align: center;
						padding-top: 7px;
						font-size: 10px;

						i {
							color: $bjc-blue;
							text-align: center;
						}
					} /* play-button */
				} /* play-button-container */
			} /* video-preview */
		} /* video-container */

		.intro-text {

			hr {
				max-width: 46px;
				border: 1px solid $bjc-blue;
				margin-bottom: 8px;
			}

			h2 {
				margin-bottom: 20px;
			}

		} /* intro-text */

	} /* intro */

	.ctas-container {
		background: $bjc-blue;
		padding: 40px 0px;
		color: #FFFFFF;

		hr {
			margin: 0 auto;
			max-width: 45px;
			margin-top: 0px;
			margin-bottom: 10px;
			border: 1px solid #FFFFFF;
		}

		h2 {
			text-align: center;
			color: #FFFFFF;
		}

		p {
			text-align: center;
			color: #FFFFFF;
		}

		#ctas {

			.cta {
				background: #FFFFFF;
				text-align: center;
				padding: 50px 20px 20px 20px;
				margin: 0px 10px;

				img {
					margin: 0 auto;
					margin-bottom: 30px;
				}

				p {
					color: #707070;
				}
			} /* cta */

			.slick-next {
				right: -10px;
				background: rgba(102, 143, 183, .5);
				min-height: 85px;
			}

			.slick-next::before {
				font-family: $fontawesome;
				content: '\f054';
				
			}

			.slick-prev {
				left: -10px;
				background: rgba(102, 143, 183, .5);
				min-height: 85px;
			}

			.slick-prev::before {
				font-family: $fontawesome;
				content: '\f053';
			}

			.slick-dots {
				bottom: -40px;
			}

			.slick-dots li.slick-active button:before {
				color: #FFFFFF;
			}

			.slick-dots li button:before {
				font-size: 45px;
				opacity: 1;
				color: rgba(102, 143, 183, 1);
			}

		} /* ctas */

	} /* ctas-container */

	.benefits {
		padding: 40px 0px;
		background: rgba(229, 239, 247, .31);

		#dnn_BenefitsRight {
			display: none;
		}

		#dnn_BenefitsRight.active {
			display: block;
		}

		#show-more-benefits.hidden {
			display: none;
		}

		hr {
			margin: 0 auto;
			max-width: 45px;
			margin-top: 0px;
			margin-bottom: 10px;
			border: 1px solid $bjc-blue;
		}

		h2 {
			text-align: center;
		}

		p {
			text-align: center;
		}

		.CollapseLink,
		.ExpandLink {
			display: none;
		}

		.BenefitsTitle:first-of-type {
			display: none;
		}

		.BenefitsTitle img {
			display: none;
		}

		.BenefitsTitle {
			display: block;
			width: 100%;
			text-decoration: none;
			padding-left: 65px;

			p {
				font-size: 20px;
				color: $bjc-blue;
				font-weight: $semi-bold;
				text-decoration: none;
				line-height: 20px;
			}
		} /* benefitstitle*/

		.BenefitsContent {

			p {
				text-align: left;
				margin-top: 10px;
				color: #333333;
			}

		} /* benefitscontent */


		/* icons for individual click to expand elements */

		.medical-dental-and-vision-coverage {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/medical-dental-and-vision-coverage.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.flexible-spending-account {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/flexible-spending-account.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.dependent-life-insurance-coverage {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/dependent-life-insurance-coverage.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.salary-continuation {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/salary-continuation.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.voluntary-short-term-disability {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/voluntary-short-term-disability.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.retirement-savings-plan {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/retirement-savings-plan.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.cme-allowance {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/cme-allowance.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.life-and-accidental-death-and-dismemberment {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/life-and-accidental-death-and-dismemberment.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.pharmacy-program {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/pharmacy-program.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.domestic-partner-benefits {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/domestic-partner-benefits.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.long-term-care-insurance {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/long-term-care-insurance.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.business-travel-accident-insurance {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/business-travel-accident-insurance.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.pension-plan {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/pension-plan.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

		.deferred-compensation-plan {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/deferred-compensation-plan.png');
			background-repeat: no-repeat;
			background-position-y: 0px;
			background-position-x: 10px;
			background-size: 35px;
			height: 34px;
		}

	} /* benefits */

	.physician-opportunities {
		padding: 40px 0px;

		hr {
			margin: 0 auto;
			max-width: 45px;
			margin-top: 0px;
			margin-bottom: 10px;
			border: 1px solid $bjc-blue;
		}

		h2 {
			text-align: center;
			line-height: 20px;
		}

		p {
			text-align: center;
		}

		.button-group {
			max-width: 400px;
			margin: 0 auto;
			margin-bottom: 30px;

			a.button {
				display: block;
				margin: 0 auto;
				margin-bottom: 10px;
			}
		} /* button-group */

	} /* physician opportunities */


	.connect-with-us {
		padding: 40px 0px;
		background: $bjc-blue;

		hr {
			margin: 0 auto;
			max-width: 45px;
			margin-top: 0px;
			margin-bottom: 10px;
			border: 1px solid #FFFFFF;
		}

		h2 {
			text-align: center;
			color: #FFFFFF;
		}

		p {
			text-align: center;
			color: #FFFFFF;
		}

		.section-title.underlined {
			color: #FFFFFF;
		}

		.form-control::placeholder {
			color: #E5EFF7 !important;
			font-size: 15px;
		}

		.form-control {
			color: #FFFFFF;
		}

		select {
			-webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;
		    background-image: url(/Portals/_default/Skins/integriTheme/Images/white-arrow.png);
		    background-size: 9px;
		    background-position-x: calc(100% - 3px);
		}

		.checkbox-list input[type=checkbox]:first-of-type {
			margin-top: 5px;
		}

		.checkbox-list input[type=checkbox]:after {
			border: 1px solid #FFFFFF !important;
		}

		.checkbox-list label span {
			color: #FFFFFF;
			font-size: 16px;
		}

		table.table.table-striped.files {

		}

		.btn.submit {
			background: #FFFFFF !important;
			color: $bjc-blue !important;
		}

	} /* connect-with-us */


} /* main.sub-page.career */

@media screen and (min-width: 768px) {

	main.sub-page.careers {

		.hero {

			.content-container {
				max-width: 245px;

				hr {
					border: 2px solid #FFFFFF;
					max-width: 33px;
					margin-top: 0px;
					margin-bottom: 4px;
					margin-left: 5px;
				}

				h2, h1 {
					font-size: 40px;
					line-height: 55px;

					span {
						font-size: 55px;
					}
				}
			} /* content-container */

			p.tag-line {
				font-size: 18px;
				line-height: 24px;
				max-width: 450px;
			}
		} /* hero */

		.intro {

			.video-container {

				.video-preview {
					background-image: url('/Portals/_default/Skins/integriTheme/Images/careers-video-preview-desktop.jpg');
					min-height: 425px;

					.play-button-container {
						border-radius: 44px;
					    height: 80px;
					    width: 80px;
					    right: 30px;
					    bottom: 10px;

						.play-button {
							border-radius: 32px;
							height: 60px;
							width: 60px;
							font-size: 22px;
							padding-top: 19px;
						}
					} /* play-button-container */
				} /* video-preview */

				.video-preview:hover {

					.play-button-container {
						background: rgba(255, 255, 255, 1);
					}
				}

			} /* video-container */


			.intro-text {
				padding-left: 15px;
			}
		} /* intro */

	} /* sub page careers */

} /* min-width 768px */

@media screen and (min-width: 992px) { 

	main.sub-page.careers {

		.hero {
			background-image: url('/Portals/_default/Skins/integriTheme/Images/careers-hero-desktop.jpg');
			background-position: center center;
			background-size: cover;
			background-repeat: no-repeat;
			padding: 80px 0px 80px 0px;

			.content-container {
				max-width: 100%;

				hr {
					margin-left: -2px;
					margin-right: auto;
				}

				h2, h1 {
					max-width: 100%;
					text-align: left;
					margin-bottom: 40px;
				}
			} /* content-container */

			p {
				margin-bottom: 0px;
			}

			p.tag-line {
				margin-left: 0px;
				margin-right: auto;
				text-align: left;
				margin-bottom: 40px;
			}

			a.button {
				margin-left: 0px;
				margin-right: auto;
				margin-bottom: 10px;
			}

			p.view-all-other {
				text-align: left;
				margin-left: 60px;
			}
		} /* hero */

		.ctas-container {

			p {
				max-width: 750px;
			    margin: 0 auto;
			    margin-bottom: 45px;
			}

			#ctas {

				.cta {
					margin: 0px 10px;
					-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
					box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
					margin-bottom: 15px;

					p {
						margin-bottom: 0px;
					}
				}

			} /* ctas*/

		} /* ctas-container */

		.benefits {
			background: #FFFFFF;

			p {
				max-width: 750px;
			    margin: 0 auto;
			    margin-bottom: 45px;
			}

			#dnn_BenefitsRight {
				display: block;
			}

			.BenefitsTitle {
				border: 1px solid #ECF1F5;
			    min-height: 120px;
			    display: flex;
			    align-items: center;
			    padding-left: 110px;
			    background-size: 60px;
			    padding-right: 60px;
			    background-position-y: 30px;
    			background-position-x: 23px;
    			transition: .8s;


			    p {
			    	text-align: left;
			    	font-size: 20px;
			    	margin-bottom: 0px;
			    	transition: 1.0s;
			    	text-align: left;
			    	justify-content: left;
			    	width: 100%;
			    	line-height: initial;
			    }
			}

			.BenefitsTitle:hover {
				background-color: #fbfbfb;
			}

			.BenefitsTitle.active {
				background: $bjc-blue;
				transition: .8s;
			    background-position-y: 30px;
    			background-position-x: 23px;
    			background-size: 60px;
    			background-repeat: no-repeat;

				p {
					color: #FFFFFF;
					transition: 1.0s;
				}
			}

			.BenefitsContent {
				-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
				box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;

				> div {
					width: 100% !important;
				}

				p {
					text-align: left;
					margin-top: 10px;
					color: #668FB7;
					margin-bottom: 0px;
					margin-top: 0px;
					padding: 20px;
					line-height: 25px;
					width: 100% !important;
				}

			} /* benefitscontent */

			.BenefitsTitle.active.medical-dental-and-vision-coverage {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/medical-dental-and-vision-coverage.png');
			}

			.BenefitsTitle.active.flexible-spending-account {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/flexible-spending-account.png');
			}

			.BenefitsTitle.active.dependent-life-insurance-coverage {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/dependent-life-insurance-coverage.png');
			}

			.BenefitsTitle.active.salary-continuation {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/salary-continuation.png');
			}

			.BenefitsTitle.active.voluntary-short-term-disability {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/voluntary-short-term-disability.png');
			}

			.BenefitsTitle.active.retirement-savings-plan {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/retirement-savings-plan.png');
			}

			.BenefitsTitle.active.cme-allowance {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/cme-allowance.png');
			}

			.BenefitsTitle.active.life-and-accidental-death-and-dismemberment {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/life-and-accidental-death-and-dismemberment.png');
			}

			.BenefitsTitle.active.pharmacy-program {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/pharmacy-program.png');
			}

			.BenefitsTitle.active.domestic-partner-benefits {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/domestic-partner-benefits.png');
			}

			.BenefitsTitle.active.long-term-care-insurance {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/long-term-care-insurance.png');
			}

			.BenefitsTitle.active.business-travel-accident-insurance {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/business-travel-accident-insurance.png');
			}

			.BenefitsTitle.active.pension-plan {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/pension-plan.png');
			}

			.BenefitsTitle.active.deferred-compensation-plan {
				background-image: url('/Portals/_default/Skins/integriTheme/Images/benefits-icons/active/deferred-compensation-plan.png');
			}

		} /* benefits */

		.physician-opportunities {
			background: #F4F6F7;

			p {
				max-width: 750px;
			    margin: 0 auto;
			    margin-bottom: 45px;
			}

			p.location {
				margin-bottom: 0px;
			}

			.post {
				width: calc(50% - 20px);
				margin: 0px 10px 20px 10px;
				float: left;
				min-height: 165px;
			}


			.button-group {
				max-width: 600px;
				margin-bottom: 50px;

				a.button {
					text-decoration: none !important;
				}

				.left {
					text-align: right;
				}

				.right {
					text-align: left;
				}
			}

		} /* physician-opportunities */

		.connect-with-us {

			p {
				max-width: 750px;
			    margin: 0 auto;
			    margin-bottom: 45px;
			}

			.avt-action-form {
				max-width: 750px;
				margin: 0 auto;
			}

		} /* connect-with-us */

	} /* sub page careers */
} /* min-width 992px */

@media screen and (min-width: 1200px) {

	main.sub-page.careers {

		.intro {
			padding: 40px 0px 55px 0px;
		}

		.ctas-container {
			overflow-y: visible;
			max-height: 725px;
			margin-bottom: 60px;
			padding-top: 80px;
		}

		.benefits {

			#dnn_BenefitsLeft {
				margin-right: 30px;
			}

			#dnn_BenefitsRight {
				margin-left: 30px;
			}
		} /* benefits */

		.physician-opportunities {
			padding-top: 60px;
			padding-bottom: 80px;
		}

		.connect-with-us {
			padding: 80px 0px;
		}

	}	/* sub page careers */
} /* min-width 1200px */


main.sub-page.open-positions {

	.blue-header {
		padding: 50px 0px 120px 0px;

		h2, h1 {
			font-size: 25px;
		}

		hr {
			margin-bottom: 5px;
		}

		.button {
			border: 0px;
			background: transparent;
			text-decoration: underline !important;
		}

		.button:hover {
			background: transparent;
		}
	}
	/* blue-header */
	#dnn_FiltersPane .DnnModule:nth-child(2) {
		.edn_module_box.active {
			margin-bottom: 40px;
		}
		#expand-categories {
			display: none;
		}
	}

	.posts-feed {
		margin-top: -105px;
	}

	.TitleH4 {
		display: none;
	}

	.category_menu {

		.click-to-open {

			a {
				background: #FFFFFF;
				display: block;
				color: $bjc-blue;
				border-radius: 0px;
				max-width: 350px !important;
				margin: 0 auto;
				padding: 15px 0px;
				margin-bottom: 80px;
			}

			a.active {
				background: #E5EFF7;
				margin-bottom: 0px;

				i::before {
					content: '\f068' !important;
					font-family: $fontawesome;
				}
			}
			/* active */
		}
		/* click to open */
		.edn_module_box {
			display: none;
			max-width: 350px;
			margin: 0 auto;
			border-radius: 0px;
			background: #FFFFFF;
			border: 0px;
			-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
			box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
			padding: 20px;

			a {
				text-decoration: none !important;
			}

			.select-a-specialty {
				border: 1px solid #DEE7EF;
				font-family: $opensans;
				cursor: pointer;
				position: relative;

				a {
					display: block;
					padding: 10px;
				}

				i.fas.fa-caret-down {
					float: right;
				}
			}
			/* select a speciality */
			.select-a-specialty.active {
				border-bottom: 0px;

				i.fas.fa-caret-down {
					transform: rotate(180deg) scaleX(-1);
				}

				a {
					background: #E5EFF7;
				}
			}

			.categories-list {
				display: none;
				border: 1px solid #DEE7EF;
				border-top: 0px;
				list-style-type: none;
				margin-left: 0px;
				margin-bottom: 0px;

				li {
					list-style-type: none;

					a {
						display: block;
						padding: 5px 10px;
						font-size: 16px;
					}
				}
			}
			/* categories-list */
			.clear-all {
				text-align: center;
				margin-top: 20px;
				font-size: 14px;
			}

			.categories-list.active {
				display: block;
			}
		}
		/* edn_module_box */
		.edn_module_box.active {
			display: block;
		}
	}
	/* category-menu */
	.info-pane {
		margin-bottom: 20px;

		.results-count,
		.active-category {
			display: inline-block;
		}

		.results-count {
			margin-right: 10px;

			p {
				margin-bottom: 0px;
				color: $bjc-blue;
				text-transform: uppercase;

				.count {
					font-size: 26px;
					font-weight: 700;
					margin-right: 4px;
				}
			}
		}
		/* results-count */
	}
	/* info-pane */
	.article_pager {
		margin-top: 50px;

		.page {
			font-size: 22px;
			border: 0px;
			box-shadow: none;
			background: transparent;
			color: #9B9B9B;
			text-decoration: none;
		}

		.active.page {
			color: $bjc-blue;
			font-weight: $bold;
		}
	}
	/* article_pager */
} /* main.sub-page-open-positions */

@media screen and (min-width: 768px) {
	main.sub-page.open-positions {

	} /* main.sub-page-open-positions */

} /* min width 768px */

@media screen and (min-width: 992px) {
	main.sub-page.open-positions {

		.blue-header {
			padding: 65px 0px 50px 0px;

			h2, h1 {
				font-size: 35px;
			}

			a.button.hollow.transparent.rounded {
				text-decoration: none !important;
				border: 1px solid #FFFFFF;
				color: #FFFFFF;
				padding: initial;
				padding: 11px 28px;
				margin-top: 20px;
			}

			a.button.hollow.transparent.rounded:hover {
				background: #FFFFFF;
				color: $bjc-blue;
			}
		} /* blue-header */

		.TitleH4 {
			display: block;
			font-size: 11px;
			text-transform: uppercase;
			color: $bjc-blue;
		}

		.posts-feed {
			margin-top: 70px;
		}

		.category_menu {
			float: left;
		}

		.category_menu .edn_module_box {
			display: block;
			background: transparent;
			display: block;
			width: initial;
			padding: 0px;
			box-shadow: none !important;
			max-width: 320px;
			margin: initial;

			.select-a-specialty {
				background: #FFFFFF;
				width: 320px;

				a {
					background: #FFFFFF;
					padding: 15px 12px;
				}
			}

			.select-a-specialty.active {

				a {
					background: #E5EFF7;
				}

			} /* select-a-specialty.active */

			.categories-list {
				background: #FFFFFF;

				li {

					a {
						padding: 7px 12px;
					}

					a:hover {
						background: #eaeef1;
					}
				}
			} /* categories-list */

		} /* edn_module_box */

	} /* main.sub-page-open-positions */
} /* min width 992px */

@media screen and (min-width: 1200px) {
	main.sub-page.open-positions {

	} /* main.sub-page-open-positions */
} /* min width 1200px */


main.sub-page.single-position {
	background: #FFFFFF;

	.careers-single-post {
		text-align: left;
		background: #FFFFFF;

		.blue-header {
			padding: 70px 0px 80px 0px;

			h2, h1 {
				text-align: left;
				font-family: $opensans;
				margin-bottom: 30px;
				font-size: 25px;
			}

			hr {
				margin-right: auto;
				margin-left: initial;
				margin-bottom: 10px;
			}

			.category {

				a {
					color: #FFFFFF;
				    text-align: left !important;
				    margin: 0px;
				    text-decoration: none;
				    background: #003B71;
				    padding: 11px 25px;
				    float: left;
				    font-size: 13px;
				    margin-right: 10px;
				}
			}
		}

	} /* careers single post */

	.position-details {
		-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
		box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
		margin-left: 15px;
		margin-right: 15px;
		margin-top: -50px;
		margin-bottom: 50px;

		.row {
			-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
			box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.08) !important;
		}

		.content {
			padding: 20px;

			.location-image {
				margin-bottom: 25px;

				img {
					width: 100%;
					height: auto;
				}
			} /* location-image */

			h3 {
				font-weight: $semi-bold;
				font-size: 22px;
				margin-bottom: 5px;
			}

			.posted-date {
				font-style: italic;
				font-weight: $light;
				color: #012E57;
				font-size: 15px;
				margin-bottom: 18px;
			}

			.details {

				.row {
					margin-bottom: 10px;
					box-shadow: none !important;
					-webkit-box-shadow: none !important;

					.detail-label {
						font-size: 15px;
						width: 160px;
						color: #012E57;
						font-weight: $semi-bold;
					}

					.data {
						width: calc(100% - 160px);
						color: #333333;
						font-size: 15px;
					}
				}
			} /* details */

			.recruiter-info {
			 	margin: 30px 0px;
				h4 {
					font-weight: $semi-bold;
					font-size: 18px;
					margin-bottom: 3px;
				}

				p {
					margin-bottom: 0px;
					font-size: 15px;

					a {
						text-decoration: none;
						color: #668FB7;
						font-size: 15px;
					}
				}
			} /* recruiter-info */

			.tool-box {
				.button {
					text-transform: uppercase;
					font-weight: $light;
					display: block;
					margin-bottom: 20px;
					color: #FFFFFF !important;
				}

				.social-share {
					text-align: center;
					margin-bottom: 0px;
					display: none;
					a {
						text-decoration: none;
						text-align: center;
					}
				}
			} /* tool-box */

		} /* content */
	} /* position-details */

	.apply-form   {
		background: #E5EFF7;
		padding: 50px 0px;
		margin-bottom: 40px;

		h2, p {
			text-align: center;
			max-width: 750px;
			margin: 0 auto;
		}

		p {
			margin-bottom: 30px;
		}

		.avt-action-form {
			max-width: 750px;
			margin: 0 auto;
		}

		input[type=checkbox] {
			margin-top: 5px;
		}
	} /* apply form */

	.related-posts {

		hr {
			margin: 0 auto;
			max-width: 45px;
			margin-top: 0px;
			margin-bottom: 10px;
			border: 1px solid $bjc-blue;
		}

		h2 {
			text-align: center;
			line-height: 20px;
		}

		p {
			text-align: center;
		}

		.post {

			h2 {
				margin-bottom: 5px;
				font-size: 22px;
			}
		}

		.button-group {
			max-width: 400px;
			margin: 0 auto;
			margin-bottom: 30px;

			a.button {
				text-decoration: underline !important;
			}
		} /* button-group */
	}

} /* main.sub-page.single-position */

@media screen and (min-width: 992px) {
	main.sub-page.single-position {

		.careers-single-post {

			.blue-header {
				padding: 70px 0px 50px 0px;

				h2, h1 {
					font-size: 35px;
					max-width: 700px;
				}

			} /* blue-header */

			.container.relative {
			  margin: 20px auto;
			  overflow: visible;
			}

			.position-details {
			  float: right;
			  width: 365px;
			  margin-top: -210px;
			  margin-left: 50px;
			  margin-bottom: 15px;
			} /* position-details */

			.position-content {
				padding-top: 40px;
				padding-bottom: 25px;
				padding-left: 20px;

			} /* position-content */

			.push {
			  float: left;
			  width: 0;
			  margin: 1em 0 0 0;
			}

		} /* careers-single-post */

		.apply-form   {
			margin-bottom: 50px;

			p {
				margin-bottom: 45px;
			}
		} /* apply form */

		.related-posts {
			padding-bottom: 50px;

			h2 {
				margin-bottom: 20px;
			}

			p {
				max-width: 750px;
			    margin: 0 auto;
			    margin-bottom: 45px;
			}

			p.location {
				margin-bottom: 0px;
			}

			.post {
				width: calc(50% - 60px);
				margin: 0px 10px 20px 10px;
				float: left;
				min-height: 165px;

				h2 {
					margin-bottom: 6px;
				}
			}


			.button-group {
				max-width: 600px;
				margin-bottom: 50px;

				a.button {
					text-decoration: none !important;
				}

				.left {
					text-align: right;
				}

				.right {
					text-align: left;
				}
			}
		}

	} /* sub page single position */

} /* min width 992px */


.hero {
	position: relative;

	.hero-color-overlay {
		position: absolute;
		z-index: 1;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background: rgba(7, 68, 119, 0.5);
	}

	.container {
		position: relative;
		z-index: 2;
	}

} /* hero */

.blue-header {
	background: linear-gradient(#0060b5 0%, #00529b 100%);
	padding: 70px 0px;
	text-align: center;
	color: #FFF;

	hr {
		width: 45px;
		border: 1px solid #FFF;
		margin: 0 auto;
		margin-bottom: 0px;
		margin-top: 0px;
	}

	h2, h1 {
		font-weight: $light;
		color: #FFFFFF;
		font-size: 22px;
		span {
			font-weight: $bold;
		}
	} /* h2 */

	p {
		margin-bottom: 0px;
		color: #FFF;
		font-size: 14px;
	}

} /* blue-header */

@media screen and (min-width: 992px) {

	.blue-header {
		padding: 85px 0px;

		h2, h1 {
			font-size: 35px;
		}

		p {
			font-size: 18px;
		}

		hr {
			margin-bottom: 15px;
		}
	}
} /* min-width 992px */


/* contact form styles */

@media screen and (min-width: 768px) {
	.section-email, .section-phone {
		width: 50%;
		float: left;
	}
}
/* min width 768 */

.section-title.underlined {
	text-decoration: none !important;
	border-bottom: 0px !important;
	color: #003B71;
	font-weight: $semi-bold;
	font-size: 15px;
}

.form-control {
	padding-left: 15px !important;
	margin-bottom: 10px !important;
}

input[type=text],
select {
	-webkit-box-shadow: inset 0 -1px 0 #cedbe7 !important;
	box-shadow: inset 0 -1px 0 #cedbe7 !important;
	font-size: 15px;
}

select::-ms-expand {
    display: none;
}

textarea {
	border: 1px solid #cedbe7 !important;
}

.form-control::placeholder {
	color: #707070 !important;
	font-size: 15px;
}


#dnn391CommentsandQuestions{
	padding: 20px;
}

#dnn391CommentsandQuestions::placeholder {
	opacity: 0;
}

.checkbox-list {
	margin-top: -20px;

	.control-label {
		color: #707070;
		font-size: 10px;
		font-style: italic;
		font-weight: 300;
	}

	input[type=checkbox]:after {
		border: 1px solid #707070 !important;
		border-radius: 0px !important;
	}

	input[type=checkbox]:checked:after {
		background-color: $baby-blue !important;
		border-color: $baby-blue !important;
	}

	label {

		span {
			padding-left: 10px !important;
			font-size: 16px;
			line-height: 18px;
		}
		
	}

} /* checkbox-list */

.btn.submit {
	font-weight: $regular !important;
	background: #00529B !important;
	color: #FFFFFF !important;
	border-radius: 5px !important;
	text-decoration: none !important;
	text-align: center !important;
	padding: 10px 28px !important;
	display: inline-block !important;
	cursor: pointer !important;
	margin: 0 auto;
	font-size: 16px;
	min-width: 211px;
	max-width: 211px;
	display: block !important;
	text-transform: uppercase !important;
	box-shadow: none !important;
	margin-top: 50px !important;
}

.btn.submit:hover {
	background: #012E57 !important;
    color: #FFFFFF !important;
}


table.table.table-striped.files * {
	background: $bjc-blue;
	color: #FFFFFF;
	border: 0px;
}

table.table.table-striped.files p {
	text-align: left;
}

table.table.table-striped.files td {
	max-width: 150px;
}

.fileinput-button {
	border-radius: 0px !important;
	background: #668FB7 !important;
	color: #FFFFFF;
	box-shadow: none !important;
	padding: 20px 50px !important;
	font-size: 16px;
}


/* opportunities */

.post-container {
	margin-bottom: 40px;

	.post {
		-webkit-box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
		box-shadow: 0px 3px 15px rgba(0, 32, 61, 0.16) !important;
		padding: 55px 20px 25px 20px;
		margin-bottom: 20px;
		position: relative;

		h2, p {
			text-align: left !important;
		}

		a {
			text-decoration: none;
		}

		h2 {
			color: $bjc-blue;
			margin-bottom: 6px;

			a {
				font-size: 20px;
				font-weight: $semi-bold;
			}
		}

		h2 a:after {
			content: "\f0da";
			font-family: $fontawesome;
			padding-left: 10px;
			font-size: 15px;
		}

		p {
			margin-bottom: 0px;
		}

		.category {
			background: #E5EFF7;
			position: absolute;
			top: 15px;
			left: 0px;
			padding: 5px 10px;

			a {
				color: $bjc-blue;
				font-size: 11px;
				text-transform: uppercase;
			}

			a:nth-of-type(2) {
				display: none;
			}

		} /* category */

		.location {
			font-size: 14px;

			i {
				color: $bjc-blue;
				padding-right: 3px;
			}
		}
	} /* post */
	
} /* post-container */

@media screen and (min-width: 992px) {

	.post-container {

		.post {

			h2 a:after {
				display: none;
			}

			.details-link {
				font-size: 12px;
				text-decoration: underline;
				line-height: 35px;
				display: block;
				text-align: right;

				i {
					font-size: 12px; 
					padding-left: 3px;
					padding-top: 2px;
				}
			} /* details-link */

		} /* post */
	}

} /* min widht 992px */

#bjc-ps-hm-separator {
	text-align: center;
    font-weight: 600;
    margin-bottom: 15px;
    color: $bjc-blue;
    padding-right: 20px;
}


.fad-sidebar .content #bjc-ps-hm-separator {
	padding-right: 0px;
	margin-bottom: 10px;
}

/* SEARCH RESULTS PAGE */

.dnnSearchResultContainer > .dnnSearchResultItem-Subset > .dnnSearchResultItem-Title {
	display: none !important;
}

.dnnSearchResultContainer > .dnnSearchResultItem-Subset .dnnSearchResultItem-Others {
	display: none !important;
}

.dnnSearchResultItem-Others {
	display: none !important;
}

.dnnSearchResultAdvancedTip {
	display: none !important;
}

.dnnSearchBoxPanel {
	height: 54px;

	.dnnSearchBox {
		height: 37px;
		margin-left: 10px;
		margin-right: 10px;

		input[type="text"] {
			width: calc(100% - 50px) !important;
		}

		a.dnnSearchBoxClearText {
			right: 10px !important;
		}

		a.dnnSearchButton {
			right: 40px !important;
		}

		.dnnSearchBox_advanced {
			display: none;
		}

		.dnnSearchBoxClearAdvanced {
			display: none;
		}
	}
}

.dnnSearchResultContainer > .dnnSearchResultItem > .dnnSearchResultItem-Title {
	text-decoration: none !important;
}

.dnnSearchResultItem-Title a {
	text-decoration: none !important;
	font-size: 20px;
}

.dnnSearchResultPanel  {
	display: none;
}

/* SITEMAP PAGE STYLING */
.DnnModule-SimpleSiteMap {

	a {
		margin-bottom: 20px;
		display: block;
	}

} /* simple site map */

.alert-info {
	background: $bjc-blue;
}

.submit-confirm {
	background: $bjc-blue !important;

	h2 {
		color: #FFFFFF !important;
	}

	* {
		color: #FFFFFF;
	}
}
.careers {
	.submit-confirm {
		background: #00529B !important;
		h2 {
			color: #FFFFFF !important;
		}
		* {
			color: $bjc-blue;
		}
	}
}

/* OVERRIDES */
.button.topModule {
	display: none !important;
}

html .bstrap3-material .alert-info {
	box-shadow: none !important;
	-webkit-box-shadow: none !important;
	border-color: transparent;
}

.BenefitsContent {
	> div {
		width: 100% !important;
		
		@media screen and (max-width: 768px) {
			padding: 0 20px 0 66px !important;
		}

	}
}